import React from 'react'
import { Link, graphql } from 'gatsby'
import ArticleGrid from '../components/HomeComponents/ArticleGrid'

const TagTemplate = ({ data, location, pageContext }) => (
  <section>
    <div className="wrapper--s">
      <div className="flex-vcenter width-100">
        <span className="font--r fontsize-l c-black paddingright-m whitespace-nowrap">Articles tagged: { data.tag.data.name.text }</span>
        <div className="marginbottom-xxl margintop-xxl position-relative display-block width-100 borderwidth-0 borderbottomwidth-1 borderstyle-solid bordercolor-grey--l breakpoint-m--marginbottom-xl breakpoint-m--margintop-xl flexgrow-1">
        </div>
        <Link to="/" className="link link-purple link--underline font--r fontsize-s marginleft-m whitespace-nowrap">Retour Accueil</Link>
        
      </div>
      {data.filteredArticles != null &&
        <div>
          <ArticleGrid 
              data={data.filteredArticles}
          />
        </div>
      }
      
    </div>
  </section>
)

export default TagTemplate

export const query = graphql`
fragment articleFieldsTags on PrismicArticle {
  slugs
  id
  uid
  data {
    title {
      text
    }
    preview {
      text
    } 
    cover {
      url
    }
    category {
      document {
        id
        data {
          category_name {
            text
          }
        }
        uid
      }
    }
    publication_date(formatString:"MMM D, YYYY")
    author {
      document {
        id
        data {
          name {
            text
          }
          image {
            url
          }
        }
      }
    }
  }
}
query TagTemplate($id: String!, $uid: String!){
  tag: prismicTag(id: {eq: $id}) {
    uid
    id
    data {
      name {
        text
      }
    }
  }
  filteredArticles: allPrismicArticle(
    filter: {
      data: {
        text_tags: {
          regex: $uid
        }
      }
    }
  ) {
    edges {
      node {
        ...articleFieldsTags
      }
    }
  }
}
`